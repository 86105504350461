import { Textfit } from "react-textfit"
import styled from "styled-components"

export const OfferHeading = styled(Textfit)`
  width: 100%;

  @media (max-width: 768px) {
    text-align: center;
  }
`
