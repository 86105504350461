import ReactMarkdown from "react-markdown"
import styled, { css, keyframes } from "styled-components"

import SideArrowsImage from "../../assets/images/dynamic-overlay/side-arrows.svg"

const MIN_HEIGHT_MOBILE = 500
const MIN_HEIGHT_DESKTOP = "540px"
const HEIGHT_DESKTOP = "75vh"
const MAX_HEIGHT_DESKTOP = "800px"

export const Banner = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;

  background-color: #f7f7f7;

  @supports (top: clamp(${MIN_HEIGHT_DESKTOP}, ${HEIGHT_DESKTOP}, ${MAX_HEIGHT_DESKTOP})) {
    :after {
      content: "";

      position: absolute;
      top: calc(75vh + ${props => props.theme.components.navigationBar.atTopHeight.pixels});
      left: 0;
      bottom: 0;

      height: 200px;
      width: 100%;

      background-color: #f7f7f7;

      clip-path: ellipse(60vw 25% at 50vw 0%);

      @media (min-width: 900px) {
        clip-path: ellipse(60vw 80% at 50vw 0%);
      }

      @media (max-width: 899px) and (orientation: landscape) {
        top: calc(${MIN_HEIGHT_MOBILE}px + ${props => props.theme.components.navigationBar.atTopHeight.pixels});
      }

      @media (min-width: 900px) {
        top: calc(
          clamp(${MIN_HEIGHT_DESKTOP}, ${HEIGHT_DESKTOP}, ${MAX_HEIGHT_DESKTOP}) + ${props => props.theme.components.navigationBar.atTopHeight.pixels}
        );
      }
    }
  }
`

export const Inner = styled.div`
  position: relative;

  overflow: hidden;

  display: flex;
  justify-content: center;

  width: calc(100% - ${props => props.theme.boxLayout.margin.raw * 2}px);
  max-width: 1280px;
  height: 75vh;

  margin: ${props => `${props.theme.components.navigationBar.atTopHeight.pixels} ${props.theme.boxLayout.margin.value} 0`};
  box-sizing: border-box;

  background-color: #f0f0f0;

  border-radius: 12px;

  @media (max-width: 899px) and (orientation: landscape) {
    height: ${MIN_HEIGHT_MOBILE}px;
  }

  @media (min-width: 900px) {
    min-height: ${MIN_HEIGHT_DESKTOP};
    height: ${HEIGHT_DESKTOP};
    max-height: ${MAX_HEIGHT_DESKTOP};
  }
`
const LeftSideArrowAnim = keyframes`
  from {
    transform: translateX(-25%) rotateZ(180deg);
  }

  to {
    transform: translateX(0) rotateZ(180deg);
  }
`

const RightSideArrowAnim = keyframes`
  from {
    transform: translateX(25%);
  }

  to {
    transform: translateX(0);
  }
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;

  a,
  button {
    flex-grow: 1;
  }

  ::before {
    content: "";
    width: 32px;
    height: 32px;

    background-image: url(${SideArrowsImage});
    background-repeat: no-repeat;

    transform: rotateZ(180deg);

    animation: ${LeftSideArrowAnim} infinite forwards 0.8s ease-in-out alternate;
  }

  ::after {
    content: "";
    width: 32px;
    height: 32px;

    background-image: url(${SideArrowsImage});
    background-repeat: no-repeat;

    animation: ${RightSideArrowAnim} infinite forwards 0.8s ease-in-out alternate;
  }
  @media (min-width: 900px) {
    display: none;
  }
`

export const ColouredOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  z-index: 1;

  background: linear-gradient(180deg, rgba(255, 102, 0, 0.9) 0%, rgba(241, 130, 37, 0.9) 100%);

  @media (min-width: 900px) {
    right: 20%;
    clip-path: polygon(0 100%, 0 0, 100% 0%, 75% 50%, 100% 100%);
  }

  ::after {
    content: "";

    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;

    @media (min-width: 900px) {
      background: radial-gradient(rgba(253, 240, 140, 0.8) 0%, rgba(253, 240, 140, 0) 100%);
    }

    /* one row is 6 values. */
    /* clip-path: polygon(
      0% 100%,
      50% 80%,
      100% 100%,
      100% 99.5%,
      50% 79.5%,
      0% 99.5%,
      0% 97%,
      50% 77%,
      100% 97%,
      100% 96.5%,
      50% 76.5%,
      0% 96.5%,
      0% 94%,
      50% 74%,
      100% 94%,
      100% 93.5%,
      50% 73.5%,
      0% 93.5%,
      0% 91%,
      50% 71%,
      100% 91%,
      100% 90.5%,
      50% 70.5%,
      0% 90.5%,
      0% 88%,
      50% 68%,
      100% 88%,
      100% 87.5%,
      50% 67.5%,
      0% 87.5%
    ); */
  }

  @media (min-width: 900px) {
    bottom: 0;
    right: 20%;

    ::after {
      clip-path: polygon(
        100% 100%,
        75% 50%,
        100% 0%,
        99.5% 0,
        74.5% 50%,
        99.5% 100%,
        97% 100%,
        72% 50%,
        97% 0,
        96.5% 0,
        71.5% 50%,
        96.5% 100%,
        94% 100%,
        69% 50%,
        94% 0,
        93.5% 0,
        68.5% 50%,
        93.5% 100%,
        91% 100%,
        66% 50%,
        91% 0,
        90.5% 0,
        65.5% 50%,
        90.5% 100%,
        88% 100%,
        63% 50%,
        88% 0,
        87.5% 0,
        62.5% 50%,
        87.5% 100%
      );
    }
  }
`

export const Content = styled.div`
  z-index: 1;

  display: flex;
  flex-direction: column;

  height: 40%;

  padding: 0 20px;

  @media (min-width: 900px) {
    width: 100%;
    height: 100%;

    justify-content: space-between;

    padding: 0 40px;
  }
`

const textAttributes = css`
  position: relative;

  font-family: ${props => props.theme.fonts.leoUniverse};

  color: ${props => (props.theme.isBlackText ? "#000" : "#fff")};

  p {
    margin: 0;
  }
`

const Title = styled(ReactMarkdown)`
  ${textAttributes};

  margin: auto 0 20px 0;

  font-weight: bold;
  text-align: center;

  font-size: 2.5rem;
  line-height: 3.5rem;

  h1,
  h2,
  h3 {
    margin: 0;
  }

  h1 {
    font-size: 2.5rem;
    line-height: 3.5rem;
  }

  h2 {
    font-size: 2rem;
    line-height: 3rem;
  }

  h3 {
    font-size: 1.5rem;
    line-height: 2.5rem;
  }

  @media (min-width: 900px) {
    margin: auto 0;

    text-align: left;

    font-size: 4rem;
    line-height: 5rem;

    h1 {
      font-size: 4rem;
      line-height: 5rem;
    }

    h2 {
      font-size: 3rem;
      line-height: 4rem;
    }

    h3 {
      font-size: 2rem;
      line-height: 3rem;
    }
  }

  @media (min-width: 1000px) {
    font-size: 5rem;
    line-height: 6rem;

    h1 {
      font-size: 5rem;
      line-height: 6rem;
    }

    h2 {
      font-size: 3.5rem;
      line-height: 4.5rem;
    }

    h3 {
      font-size: 2rem;
      line-height: 3rem;
    }
  }
`

export const PreSpinTitle = styled(Title)`
  opacity: 1;

  @media (min-width: 900px) {
    max-width: 55%;

    margin-right: 5%;
  }
`
