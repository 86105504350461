/* eslint-disable react/require-default-props */
import PropTypes from "prop-types"
import React from "react"

import * as S from "./style"

const LUOfferHeading = ({ maxTextFitSize, children, shouldDisplay }) => {
  return (
    shouldDisplay && (
      <S.OfferHeading mode="single" max={maxTextFitSize}>
        {children}
      </S.OfferHeading>
    )
  )
}

LUOfferHeading.propTypes = {
  maxTextFitSize: PropTypes.number.isRequired,
  children: PropTypes.objectOf(PropTypes.any).isRequired,
  shouldDisplay: PropTypes.bool.isRequired
}

export default LUOfferHeading
