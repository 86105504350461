import styled, { css } from "styled-components"

export const Terms = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;

  box-sizing: border-box;

  width: 100%;

  background-color: ${props => props.backgroundColour};

  font-family: ${props => props.theme.fonts.primary};
`

const subListItem = css`
  li {
    font-size: 1em;
    list-style: inside;
    list-style-type: circle;
  }
`

const markdownStyling = props => css`
  * {
    font-size: 0.95em;
    line-height: 1.65em;
  }

  p strong {
    font-weight: 700;
  }

  ol,
  ul {
    padding: 10px 30px;
  }

  ul {
    li {
      list-style-type: disc;
      color: ${props.$textColour};

      ${subListItem};

      p {
        margin: 0;
      }
    }
  }

  ol {
    li {
      list-style-type: decimal;
      color: ${props.$textColour};

      ${subListItem};

      p {
        margin: 0;
      }
    }
  }

  ol[type="a"] {
    li {
      list-style-type: lower-alpha;
    }
  }

  a {
    text-decoration: underline;
    color: #000;
  }
`

export const Inner = styled.div`
  display: flex;
  justify-content: center;

  width: 100%;
`
export const Accordion = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const AccordionBody = styled.div`
  height: ${props => (props.isOpened ? "auto" : "3em")};
  overflow-y: hidden;

  & > p:first-of-type > strong {
    font-size: 1.3rem;
    padding-left: 10px;
  }
`

export const IconWrapper = styled.div`
  position: absolute;
  width: 100%;
  padding: 30px ${props => props?.theme?.boxLayout?.margin?.value || "20px"};
  padding-bottom: ${props => props.isOpened && "20px"};
  top: -30px;
  left: -${props => props?.theme?.boxLayout?.margin?.value || "20px"};
  height: 3em;
  cursor: pointer;
`

export const Icon = styled.img`
  position: absolute;
  right: 0;
  top: 42px;
  max-width: 20px;
  padding-right: 10px;
`

export const Markdown = styled.div`
  ${props =>
    markdownStyling({
      $borderRadius: props.$borderRadius,
      $titleColour: props.$titleColour,
      $textColour: props.$textColour
    })};

  position: relative;
  max-width: 1024px;
  width: 100%;

  margin: 30px ${props => props?.theme?.boxLayout?.margin?.value || "20px"};

  ${props => css`
    border-radius: ${props.$borderRadius} ${props.$borderRadius} 0 0;
  `}
`
