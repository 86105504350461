import React, { useState, useEffect } from "react"
import Img from "gatsby-image"
import PropTypes from "prop-types"
import rehypeRaw from "rehype-raw"

import { GATSBY_IMAGE_FLUID_PROPTYPE, GATSBY_IMAGE_FIXED_PROPTYPE } from "../../helpers/constants"
import { getDangerousHtml, getMarkdownHtml } from "../../helpers/index"
import { GtmEventType, GtmAction } from "../../utils/handlers/gtmHandler"

import arrow1 from "../../assets/images/icons/arrow-1.svg"
import arrow2 from "../../assets/images/icons/arrow-2.svg"
import arrow3 from "../../assets/images/icons/arrow-3.svg"
import whiteArrows from "../../assets/images/icons/bottom-arrows.svg"

import svg from "../../assets/images/icons/side-arrows.svg"
import whiteSvg from "../../assets/images/icons/side-arrows-white.svg"

import LiveTicker from "../LiveTicker"
import LUBannerLogos from "../LUBannerLogos"
import LUButton from "../LUButton"
import OfferHeading from "../LUOfferHeading"
import * as S from "./style"
import useEditorMode from "../LUEditorMode"

const imageStyleProps = {
  style: {
    position: "relative",
    top: 0,
    width: "100%",
    height: "100%"
  },
  imgStyle: {
    objectFit: "cover"
  }
}

const LUBannerNew = ({
  fluidImage,
  markdown,
  pageType,
  legalLine,
  ctaText,
  ctaUrl,
  ctaShouldPulsate,
  ctaIconUrl,
  isOrangeCTA,
  paymentLogos,
  ribbonText,
  bannerBadge,
  isCanada,
  isMobile,
  urlParams,
  isOrangeBgLayout,
  newOfferMarkdown,
  hasJackpot,
  jackpotTickerImage,
  jackpotTickerImageMobile,
  jackpotFeedUrl,
  hasPageLoaded,
  signUpLinksDisabled,
  orangeHighlight
}) => {
  const [shouldDisplay, setShouldDisplay] = useState(false)

  useEffect(() => {
    // Only display content after the font has loaded. This prevents
    // issues with react-textfit not sizing correctly on page load.
    document.fonts.ready.then(() => {
      setShouldDisplay(true)
    })
  }, [hasPageLoaded])

  const ctaGtmData = {
    eventType: GtmEventType.BUTTON_CLICK,
    action: GtmAction.MAIN_CTA_CLICK,
    label: ctaText
  }

  const editorMode = useEditorMode({
    bannerImage: fluidImage,
    bannerOffer: newOfferMarkdown || markdown,
    bannerCtaText: ctaText
  })

  const parts = typeof window !== "undefined" && new URL(window.location).hostname.split(".")

  const isSports = pageType === "sportsbook"

  const domainName = parts[1]

  const markdownLink = ({ href, children }) => {
    return href.includes(domainName) ? (
      <a href={`${href}?${urlParams}`} target="_blank" rel="noreferrer">
        {children}
      </a>
    ) : (
      <a href={href} target="_blank" rel="noreferrer">
        {children}
      </a>
    )
  }

  return (
    <S.Banner $isOrangeBgLayout={isOrangeBgLayout}>
      <S.Inner>
        {editorMode?.Editor}
        {ribbonText && (
          <S.Ribbon $isSports={isSports}>
            <S.RibbonText dangerouslySetInnerHTML={getDangerousHtml(ribbonText)} />
          </S.Ribbon>
        )}
        <S.BannerMobile>
          {fluidImage && <Img fluid={editorMode?.bannerImage ?? fluidImage} {...imageStyleProps} />}
          {isMobile && !isOrangeBgLayout && (
            <S.PaymentLogos>{paymentLogos && <LUBannerLogos images={paymentLogos} isCanada={isCanada} />}</S.PaymentLogos>
          )}
          {hasJackpot && jackpotFeedUrl && (
            <LiveTicker url={jackpotFeedUrl} isMobile={isMobile} jackpotTickerImage={isMobile ? jackpotTickerImageMobile : jackpotTickerImage} />
          )}
          {bannerBadge && (
            <S.BannerBadge>
              <img src={bannerBadge} alt="" />
            </S.BannerBadge>
          )}
        </S.BannerMobile>
        <S.Content>
          {newOfferMarkdown || editorMode?.Editor ? (
            <S.NewMarkdown
              $isSports={isSports}
              $isOrangeBgLayout={isOrangeBgLayout}
              $orangeHighlight={orangeHighlight}
              rehypePlugins={[rehypeRaw]}
              components={{
                h1: ({ children }) => {
                  return (
                    <OfferHeading maxTextFitSize={isMobile ? 45 : 65} shouldDisplay={shouldDisplay}>
                      <h1>{children}</h1>
                    </OfferHeading>
                  )
                },
                h2: ({ children }) => {
                  return (
                    <OfferHeading maxTextFitSize={isMobile ? 32 : 45} shouldDisplay={shouldDisplay}>
                      <h2>{children}</h2>
                    </OfferHeading>
                  )
                },
                h3: ({ children }) => {
                  return (
                    <OfferHeading maxTextFitSize={isMobile ? 20 : 30} shouldDisplay={shouldDisplay}>
                      <h3>{children}</h3>
                    </OfferHeading>
                  )
                }
              }}
            >
              {editorMode?.bannerOffer ?? getMarkdownHtml(newOfferMarkdown)}
            </S.NewMarkdown>
          ) : (
            <S.Markdown isItalic={pageType === "sportsbook"} rehypePlugins={[rehypeRaw]}>
              {editorMode?.bannerOffer ?? getMarkdownHtml(markdown)}
            </S.Markdown>
          )}
          {!signUpLinksDisabled && (
            <S.CtaContainer>
              {isMobile && (
                <S.Arrows>
                  <img src={isOrangeBgLayout ? whiteSvg : svg} alt="arrow" />
                </S.Arrows>
              )}
              <LUButton
                url={ctaUrl}
                bold
                gtmData={ctaGtmData}
                isPulsating={ctaShouldPulsate}
                isOrangeCTA={isOrangeCTA}
                iconUrl={ctaIconUrl}
                margin="20px 0 0 0"
              >
                {editorMode?.bannerCtaText ?? ctaText}
              </LUButton>
              {isMobile && (
                <>
                  <S.Arrows>
                    <img src={isOrangeBgLayout ? whiteSvg : svg} alt="arrow" />
                  </S.Arrows>
                  {isOrangeBgLayout ? (
                    <S.BottomArrow>
                      <img src={whiteArrows} alt="arrow" />
                    </S.BottomArrow>
                  ) : (
                    <S.BottomArrow>
                      <img src={arrow1} alt="arrow" />
                      <img src={arrow2} alt="arrow" />
                      <img src={arrow3} alt="arrow" />
                    </S.BottomArrow>
                  )}
                </>
              )}
            </S.CtaContainer>
          )}

          {!isMobile && legalLine && (
            <S.LegalLine
              rehypePlugins={[rehypeRaw]}
              components={{
                a: markdownLink
              }}
            >
              {legalLine}
            </S.LegalLine>
          )}
        </S.Content>
        {isOrangeBgLayout && isMobile && (
          <S.PaymentLogos>{paymentLogos && <LUBannerLogos images={paymentLogos} isCanada={isCanada} />}</S.PaymentLogos>
        )}
        {isMobile && legalLine && (
          <S.LegalLine
            rehypePlugins={[rehypeRaw]}
            components={{
              a: markdownLink
            }}
          >
            {legalLine}
          </S.LegalLine>
        )}
        {!isMobile && paymentLogos && <LUBannerLogos images={paymentLogos} isCanada={isCanada} />}
      </S.Inner>
    </S.Banner>
  )
}

LUBannerNew.propTypes = {
  fluidImage: GATSBY_IMAGE_FLUID_PROPTYPE.isRequired,
  markdown: PropTypes.objectOf(PropTypes.any),
  pageType: PropTypes.string,
  legalLine: PropTypes.string,
  ctaText: PropTypes.string.isRequired,
  ctaUrl: PropTypes.string.isRequired,
  ctaShouldPulsate: PropTypes.bool,
  isOrangeCTA: PropTypes.bool,
  ctaIconUrl: PropTypes.string,
  paymentLogos: PropTypes.arrayOf(
    PropTypes.shape({
      fixed: GATSBY_IMAGE_FIXED_PROPTYPE,
      title: PropTypes.string,
      description: PropTypes.string
    })
  ),
  hidePaymentLogos: PropTypes.bool,
  ribbonText: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  bannerBadge: GATSBY_IMAGE_FLUID_PROPTYPE,
  isCanada: PropTypes.bool,
  isMobile: PropTypes.bool.isRequired,
  urlParams: PropTypes.string.isRequired,
  jackpotTickerImage: PropTypes.string,
  jackpotTickerImageMobile: PropTypes.string,
  jackpotFeedUrl: PropTypes.string,
  hasJackpot: PropTypes.bool,
  isOrangeBgLayout: PropTypes.bool,
  newOfferMarkdown: PropTypes.objectOf(PropTypes.any),
  hasPageLoaded: PropTypes.bool,
  signUpLinksDisabled: PropTypes.bool.isRequired,
  orangeHighlight: PropTypes.bool
}

LUBannerNew.defaultProps = {
  markdown: null,
  pageType: null,
  legalLine: null,
  ctaShouldPulsate: false,
  isOrangeCTA: false,
  ctaIconUrl: null,
  paymentLogos: null,
  hidePaymentLogos: false,
  ribbonText: null,
  bannerBadge: null,
  isCanada: false,
  hasJackpot: false,
  jackpotTickerImage: null,
  jackpotTickerImageMobile: null,
  jackpotFeedUrl: null,
  isOrangeBgLayout: false,
  newOfferMarkdown: null,
  hasPageLoaded: false,
  orangeHighlight: false
}

export default LUBannerNew
