/* eslint-disable no-underscore-dangle */
import styled, { css } from "styled-components"
import ReactMarkdown from "react-markdown"

import { palette } from "../../styles"
import { Res } from "../../utils/constants"

const HEIGHT = 110
const LAYOUT_CHANGE_BREAKPOINT = 860

export const ThreeSteps = styled.div`
  position: relative;

  z-index: 1;

  display: flex;
  justify-content: center;

  min-height: unset;
  max-height: unset;

  margin: 24px ${props => props.theme.boxLayout.margin.value} 10px;

  @media (min-width: ${LAYOUT_CHANGE_BREAKPOINT}px) {
    min-height: ${HEIGHT}px;

    ${({ overlap }) =>
      overlap
        ? css`
            transform: translateY(-${HEIGHT / 2}px);

            margin: 0 ${props => props.theme.boxLayout.margin.raw * 2}px calc(84px + -${HEIGHT / 2}px);
          `
        : css`
            margin: 29px ${props => props.theme.boxLayout.margin.raw * 2}px calc(84px + -${HEIGHT / 2}px) 0px;
          `}
    ${({ arePaymentsDisplayedInBanner }) =>
      arePaymentsDisplayedInBanner &&
      css`
        transform: translateY(-22px);
      `}
  }

  @media (min-width: ${Res._1024}px) {
    margin: 0 60px calc(84px + -${HEIGHT / 2}px);
  }
`

export const Inner = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  max-width: 1140px;
  margin: 0 auto;

  @media (min-width: ${LAYOUT_CHANGE_BREAKPOINT}px) {
    flex-direction: row;
    justify-content: center;

    padding: 0 15px;

    background: #fff;

    border-radius: 12px;

    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.05);

    transition: box-shadow 0.25s ease-in-out;

    :hover {
      box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
    }
  }

  @media (max-width: ${Res._1024}) {
    padding: 0 30px;
  }
`

const arrowProperties = css`
  content: "";

  position: absolute;

  width: 24px;
  height: 24px;

  transform: rotateZ(45deg);
`

export const Step = styled.a`
  position: relative;

  display: flex;
  align-items: center;

  min-height: 90px;

  background-color: #fff;

  border-radius: 12px;

  text-decoration: none;

  @media (max-width: ${LAYOUT_CHANGE_BREAKPOINT - 1}px) {
    padding: 0 20px;

    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.08);

    transition: box-shadow 0.25s ease-in-out;

    :hover {
      box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
    }

    :not(:last-child) {
      margin-bottom: 10px;

      // Arrow head (white)
      ::before {
        ${arrowProperties};
        content: "";

        bottom: -12px;
        ${({ index }) => (index % 2 ? "right" : "left")}: 30px;
        z-index: 2;

        background-color: #fff;

        box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.08);
      }

      // Arrow head (gray)
      ::after {
        ${arrowProperties};
        content: "";

        bottom: -22px;
        ${({ index }) => (index % 2 ? "right" : "left")}: 30px;
        z-index: 1;
      }
    }
  }

  @media (min-width: ${LAYOUT_CHANGE_BREAKPOINT}px) {
    flex-direction: row;
    justify-content: center;

    flex-grow: 1;
    flex-basis: 0;

    :not(:last-child) {
      margin-right: 20px;
    }
  }
`

export const Description = styled(ReactMarkdown)`
  width: max-content;

  margin-left: 8px;

  h4 {
    color: ${palette.crusta};
    font-size: 1.3em;
    margin: 10px 0;
  }

  p {
    line-height: 1.2;
    margin: 8px 0;
    color: #3f3f3f;
  }

  @media (min-width: ${LAYOUT_CHANGE_BREAKPOINT}px) {
    max-width: 200px;
  }
`

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 50px;
  height: 50px;
  min-width: 50px;
  min-height: 50px;

  margin-right: 15px;
`

export const Icon = styled.img`
  width: 100%;
  height: 100%;

  width: 35px;
  height: 35px;
`
export const NumberIcon = styled.div`
  border-radius: 50%;
  width: 50px;
  height: 50px;
  background: #ff7e25;

  display: flex;
  justify-content: center;
  align-items: center;

  span {
    color: #fff;
    font-size: 1.8em;
  }
`
