/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import rehypeRaw from "rehype-raw"

import ReactMarkdown from "react-markdown"
import { CLICK, TERMS, SMOOTH } from "../../helpers/constants"
import * as S from "./style"
import { pushDatalayer } from "../../utils"

import chevronDown from "../../assets/images/icons/icon-chevron-down.svg"
import chevronUp from "../../assets/images/icons/icon-chevron-up.svg"

const Terms = ({
  data,
  urlParams,

  showCollapsibleTerms,
  backgroundColour,
  borderRadius,
  titleColour,
  textColour
}) => {
  const clickHandler = event => {
    const {
      srcElement: { href: url }
    } = event

    const isTermsLink = url && url.includes(`#${TERMS}`)
    const isLegalLineLink = !!(url && event.target.closest("#legal-line"))

    if (isTermsLink) {
      // eslint-disable-next-line no-param-reassign

      event.preventDefault()

      const terms = document.getElementById("terms-wrapper")

      if (terms) {
        window.scrollTo({
          top: terms.offsetTop,
          left: 0,
          behavior: SMOOTH
        })
      }
    }

    if (isLegalLineLink) {
      event.preventDefault()

      const action = "Click on legal line link"
      let label = "Show Terms & Conditions"
      let redirectUrl = null

      if (!isTermsLink) {
        label = "External Link"
        redirectUrl = `${url}?${urlParams}`
      }

      pushDatalayer(action, label, redirectUrl)
    }
  }

  const [isAccordionOpen, setIsAccordionOpen] = useState(false)
  const [accordionIcon, setAccordionIcon] = useState(chevronDown)

  const handleAccordionClick = () => {
    setIsAccordionOpen(!isAccordionOpen)
    setAccordionIcon(isAccordionOpen ? chevronDown : chevronUp)
  }

  useEffect(() => {
    window.addEventListener(CLICK, clickHandler)

    return () => window.removeEventListener(CLICK, clickHandler)
  })

  const parts = typeof window !== "undefined" && new URL(window.location).hostname.split(".")

  const domainName = parts[1]

  const markdownLink = ({ href, children }) => {
    return href.includes(domainName) ? (
      <a href={`${href}?${urlParams}`} target="_blank" rel="noreferrer">
        {children}
      </a>
    ) : (
      <a href={href} target="_blank" rel="noreferrer">
        {children}
      </a>
    )
  }

  return (
    <>
      {showCollapsibleTerms ? (
        <S.Terms id="terms" backgroundColour={backgroundColour}>
          <S.Inner>
            <S.Markdown $borderRadius={borderRadius} $titleColour={titleColour} $textColour={textColour} id="terms-wrapper">
              <S.Accordion>
                <S.IconWrapper onClick={() => handleAccordionClick()} isOpened={isAccordionOpen}>
                  <S.Icon src={accordionIcon} alt="" />
                </S.IconWrapper>
                <S.AccordionBody isOpened={isAccordionOpen}>
                  {data && (
                    <ReactMarkdown
                      rehypePlugins={[rehypeRaw]}
                      components={{
                        a: markdownLink
                      }}
                    >
                      {data}
                    </ReactMarkdown>
                  )}
                </S.AccordionBody>
              </S.Accordion>
            </S.Markdown>
          </S.Inner>
        </S.Terms>
      ) : (
        <S.Terms id="terms" backgroundColour={backgroundColour}>
          <S.Inner>
            <S.Markdown $borderRadius={borderRadius} $titleColour={titleColour} $textColour={textColour} id="terms-wrapper">
              {data && (
                <ReactMarkdown
                  rehypePlugins={[rehypeRaw]}
                  components={{
                    a: markdownLink
                  }}
                >
                  {data}
                </ReactMarkdown>
              )}
            </S.Markdown>
          </S.Inner>
        </S.Terms>
      )}
    </>
  )
}

Terms.propTypes = {
  data: PropTypes.string,
  urlParams: PropTypes.string.isRequired,

  showCollapsibleTerms: PropTypes.bool,
  backgroundColour: PropTypes.string,
  borderRadius: PropTypes.string,
  titleColour: PropTypes.string,
  textColour: PropTypes.string
}

Terms.defaultProps = {
  data: null,
  showCollapsibleTerms: false,
  backgroundColour: "transparent",
  borderRadius: "0",
  titleColour: "#000",
  textColour: "#000"
}

export default Terms
