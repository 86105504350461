import styled from "styled-components"

import border from "../../assets/images/spinning-wheel/border.svg"
import wheel from "../../assets/images/spinning-wheel/wheel.svg"
import pointer from "../../assets/images/spinning-wheel/pointer.svg"
import spinButton from "../../assets/images/spinning-wheel/spin_btn.svg"

export const SpinningWheel = styled.div`
  z-index: 1;

  position: absolute;
  bottom: -240px;
  right: 50%;
  transform: translateX(50%);

  width: 120vw;
  max-width: 500px;
  max-height: 500px;

  aspect-ratio: 1 / 1;

  display: flex;
  justify-content: center;
  align-items: center;

  margin: 0 auto;

  @media (min-width: 900px) {
    top: 50%;
    right: -180px;

    transform: translateY(-50%) translateX(0);

    width: 80vw;
    max-width: 560px;
    max-height: 560px;
  }
`

export const Border = styled.div`
  position: absolute;
  inset: 0;

  background: url(${border}) no-repeat;

  transform: rotateZ(-180deg);
`

export const Wheel = styled.div`
  position: absolute;
  inset: 4%;
  font-family: ${props => props.theme.fonts.primary};

  background: url(${wheel}) no-repeat;
`

export const Pointer = styled.div`
  position: absolute;
  top: -10px;

  width: 8%;
  height: 100%;

  background: url(${pointer}) no-repeat;

  transform-origin: 50% 0;
`

export const SpinButton = styled.button`
  width: 25%;
  height: 25%;

  border: none;

  color: #470400;
  font-weight: bold;
  font-size: 1.25rem;

  z-index: 1;

  cursor: pointer;

  background: url(${spinButton}) no-repeat;

  color: transparent;
  user-select: none;

  @media (min-width: 900px) {
    color: unset;
    user-select: unset;
  }

  @media (min-width: 512px) {
    font-size: 2rem;
  }
`
