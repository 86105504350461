import styled, { css } from "styled-components"

export const LiveTicker = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  width: 40%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: no-repeat;
  background-size: contain;

  ${props =>
    props.theme.isMobile &&
    css`
      top: 45%;
      width: 75%;
      left: 0;
    `}

  img {
    max-width: 490px;
    width: 100%;
  }
`

export const Text = styled.p`
  color: white;
  font-size: 2.4rem;
  font-weight: bold;
  position: absolute;
  text-shadow: 2px 2px rgba(0, 0, 0, 0.5);

  @media (min-width: 512px) and (orientation: portrait) {
    font-size: 2rem;
  }

  @media (min-width: 768px) and (orientation: landscape) {
    font-size: 3.5rem;
  }
`
