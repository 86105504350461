import React, { useState, useEffect } from "react"
import * as PropTypes from "prop-types"

import { CURRENCY } from "../../helpers/constants"

import * as S from "./style"

import jackpotFeedImage from "../../assets/jackpot.png"

const LiveTicker = ({ url, jackpotTickerImage, isMobile }) => {
  const [value, setValue] = useState("")

  useEffect(() => {
    const syncTicker = async () => {
      try {
        const response = await fetch(url)
        const jsonResponse = await response.json()
        if (jsonResponse && jsonResponse.data && jsonResponse.data.leojackpot) {
          const values = Object.values(jsonResponse.data.leojackpot)
          if (values.length > 0) {
            const entries = values[0]
            // if requested by game slug
            // const entryBySlug = entries.find(entry => entry.node && entry.node.slug && entry.node.slug === "blender-blitz")
            if (!entries[0] || !entries[0].node || !entries[0].node.jackpot) {
              setValue("Invalid")

              return
            }

            const currencyValue = CURRENCY[entries[0].node.jackpot.currency] || "€"

            const stringValue = Math.floor(parseFloat(entries[0].node.jackpot.displayValue)).toLocaleString()

            setValue(currencyValue === "kr" ? `${stringValue.replaceAll(",", " ")} ${currencyValue}` : `${currencyValue}${stringValue}`)
          }
        }
      } catch (err) {
        setValue("Invalid")
      }
    }

    const interval = setInterval(() => {
      syncTicker()
    }, 5000)

    syncTicker()

    return () => clearInterval(interval)
  }, [])

  if (value === "Invalid") return null

  return (
    <S.LiveTicker $isMobile={isMobile}>
      <img src={jackpotTickerImage || jackpotFeedImage} alt="jackpot" />
      <S.Text>{value}</S.Text>
    </S.LiveTicker>
  )
}

LiveTicker.propTypes = {
  url: PropTypes.string.isRequired,
  jackpotTickerImage: PropTypes.string,
  isMobile: PropTypes.bool
}

LiveTicker.defaultProps = {
  jackpotTickerImage: null,
  isMobile: false
}

export default LiveTicker
