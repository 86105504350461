/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useRef, useEffect } from "react"
import { useScreenSize } from "../../utils/hooks"
import { Res } from "../../utils/constants"
import { getRawMarkdown } from "../../helpers/index"
import * as S from "./style"

import phoneIcon from "../../assets/images/icons/icon-phone.svg"
import desktopIcon from "../../assets/images/icons/icon-desktop.svg"

const DEVICE = {
  mobile: "mobile",
  desktop: "desktop"
}

const TAB = {
  design: "design",
  content: "content"
}

const useEditorMode = options => {
  const {
    bannerImage,
    bannerSpinWheelSpun,
    bannerSpinWheelResetCallback,
    bannerPreSpinTitle,
    bannerOffer,
    bannerSpinButtonText,
    bannerCtaText,
    mobileBreakpoint = Res._512
  } = options

  const [settings, setSettings] = useState({
    available: false,
    active: false,
    tab: TAB.design
  })

  const [data, setData] = useState({
    bannerImage: {
      mobile: null,
      desktop: null
    },
    bannerPreSpinTitle: {
      override: false,
      default: getRawMarkdown(bannerPreSpinTitle),
      value: getRawMarkdown(bannerPreSpinTitle)
    },
    bannerOffer: {
      override: false,
      default: getRawMarkdown(bannerOffer),
      value: getRawMarkdown(bannerOffer)
    },
    bannerSpinButtonText: {
      override: false,
      default: bannerSpinButtonText,
      value: bannerSpinButtonText
    },
    bannerCtaText: {
      override: false,
      default: bannerCtaText,
      value: bannerCtaText
    }
  })
  const fileInputRefs = useRef([])

  useEffect(() => {
    setSettings({
      ...settings,
      available: !!new URLSearchParams(window.location.search).get("editor")
    })

    if (!settings.available) {
      return
    }

    setTimeout(() => {
      setSettings({
        ...settings,
        active: true
      })
    }, 500)
  }, [settings.available])

  const setValue = (parentKey, value, valueKey = "value") => {
    setData({
      ...data,
      [parentKey]: {
        ...data[parentKey],
        [valueKey]: value
      }
    })
  }

  const handleToggleClick = () => setSettings({ ...settings, active: !settings.active })

  const handleEditorModeImageChange = (e, device) => setValue("bannerImage", URL.createObjectURL(e.target.files[0]), device)

  const handleUploadAreaClick = device => {
    if (data.bannerImage[device]) {
      setValue("bannerImage", null, device)
      return
    }

    fileInputRefs.current[device].click()
  }

  const handleChangeTab = e => setSettings({ ...settings, tab: e.target.dataset.name })

  const screenSize = useScreenSize()
  const device = screenSize.width.lessThanOrEqualTo(mobileBreakpoint) ? DEVICE.mobile : DEVICE.desktop

  if (!settings.available) {
    return null
  }

  const getBannerImage = () => {
    // If editor mode is not turned on, or if we do not have new banner images for this device
    if (!settings.available || (settings.available && !data.bannerImage?.[device])) {
      return bannerImage
    }

    const image = data.bannerImage[device]

    return {
      ...bannerImage,
      src: image,
      srcSet: image,
      srcWebp: image,
      srcSetWebp: image
    }
  }

  const component = (
    <>
      <S.EditorToggleButton active={settings.active} onClick={handleToggleClick} />
      <S.EditorPanel active={settings.active}>
        <S.TabContainer onClick={handleChangeTab}>
          <S.TabButton data-name={TAB.design} active={settings.tab === TAB.design}>
            Design
          </S.TabButton>
          <S.TabButton data-name={TAB.content} active={settings.tab === TAB.content}>
            Content
          </S.TabButton>
        </S.TabContainer>

        {/* Design Panel */}
        {settings.tab === TAB.design && (
          <S.TabPanel>
            <S.Section>
              <S.Heading>Banner Image</S.Heading>
              <S.SubHeading>Preview any image on this banner</S.SubHeading>
              <S.FileUploadArea onClick={() => handleUploadAreaClick(DEVICE.mobile)} active={data.bannerImage?.mobile}>
                {!data.bannerImage?.mobile ? (
                  <>
                    <S.Icon>
                      <img src={phoneIcon} alt="" />
                    </S.Icon>
                    <span>Click to upload an image for the mobile banner</span>
                  </>
                ) : (
                  <>
                    <img src={data.bannerImage.mobile} alt="" />
                    <span>Click to clear mobile image</span>
                  </>
                )}
              </S.FileUploadArea>
              <S.FileUploadArea onClick={() => handleUploadAreaClick(DEVICE.desktop)} active={data.bannerImage?.desktop}>
                {!data.bannerImage?.desktop ? (
                  <>
                    <S.Icon>
                      <img src={desktopIcon} alt="" />
                    </S.Icon>
                    <span>Click to upload an image for the desktop banner</span>
                  </>
                ) : (
                  <>
                    <img src={data.bannerImage.desktop} alt="" />
                    <span>Click to clear desktop image</span>
                  </>
                )}
              </S.FileUploadArea>
            </S.Section>
          </S.TabPanel>
        )}

        {/* Content Panel */}
        {settings.tab === TAB.content && (
          <S.TabPanel>
            {/* Reset To Before Spin */}
            {bannerSpinWheelResetCallback && (
              <S.Section>
                <S.Heading>Reset Banner State</S.Heading>
                <S.SubHeading>Resets the banner state to before the wheel being spun</S.SubHeading>
                <S.Button type="button" onClick={bannerSpinWheelResetCallback} disabled={!bannerSpinWheelSpun}>
                  Reset Spin Wheel State
                </S.Button>
                <S.Separator />
              </S.Section>
            )}

            {/* Banner Pre Spin Title */}
            {bannerPreSpinTitle && (
              <S.Section>
                <S.Heading>Banner Pre Spin Title</S.Heading>
                <S.SubHeading>Enter or paste your markdown below</S.SubHeading>
                <S.Row>
                  <input
                    type="checkbox"
                    id="override-banner-pre-spin-title"
                    name="override-banner-pre-spin-title"
                    checked={data.bannerPreSpinTitle.override}
                    onChange={e => setValue("bannerPreSpinTitle", e.target.checked, "override")}
                  />
                  <label htmlFor="override-banner-pre-spin-title">Override banner pre spin title</label>
                  <S.ResetButton onClick={() => setValue("bannerPreSpinTitle", data.bannerPreSpinTitle.default)} />
                </S.Row>
                <S.TextArea
                  value={data.bannerPreSpinTitle.value}
                  onChange={e => setValue("bannerPreSpinTitle", e.target.value)}
                  disabled={!data.bannerPreSpinTitle.override}
                />
                <S.Separator />
              </S.Section>
            )}

            {/* Banner Offer */}
            <S.Section>
              <S.Heading>Banner Offer</S.Heading>
              <S.SubHeading>Enter or paste your markdown below</S.SubHeading>
              <S.Row>
                <input
                  type="checkbox"
                  id="override-banner-offer"
                  name="override-banner-offer"
                  checked={data.bannerOffer.override}
                  onChange={e => setValue("bannerOffer", e.target.checked, "override")}
                />
                <label htmlFor="override-banner-offer">Override banner offer</label>
                <S.ResetButton onClick={() => setValue("bannerOffer", data.bannerOffer.default)} />
              </S.Row>
              <S.TextArea
                value={data.bannerOffer.value}
                onChange={e => setValue("bannerOffer", e.target.value)}
                disabled={!data.bannerOffer.override}
              />
              <S.Separator />
            </S.Section>

            {/* Banner Spin Button Text */}
            {bannerSpinButtonText && (
              <S.Section>
                <S.Heading>Spin Wheel Spin Button Text</S.Heading>
                <S.SubHeading>Enter or paste your text below</S.SubHeading>
                <S.Row>
                  <input
                    type="checkbox"
                    id="override-banner-spin-button-text"
                    name="override-banner-spin-button-text"
                    checked={data.bannerSpinButtonText.override}
                    onChange={e => setValue("bannerSpinButtonText", e.target.checked, "override")}
                  />
                  <label htmlFor="override-banner-spin-button-text">Override spin button text</label>
                  <S.ResetButton onClick={() => setValue("bannerSpinButtonText", data.bannerSpinButtonText.default)} />
                </S.Row>
                <S.TextInput
                  value={data.bannerSpinButtonText.value}
                  onChange={e => setValue("bannerSpinButtonText", e.target.value)}
                  disabled={!data.bannerSpinButtonText.override}
                />
                <S.Separator />
              </S.Section>
            )}

            {/* Banner CTA Text */}
            <S.Section>
              <S.Heading>Banner CTA Text</S.Heading>
              <S.SubHeading>Enter or paste your text below</S.SubHeading>
              <S.Row>
                <input
                  type="checkbox"
                  id="override-banner-cta-text"
                  name="override-banner-cta-text"
                  checked={data.bannerCtaText.override}
                  onChange={e => setValue("bannerCtaText", e.target.checked, "override")}
                />
                <label htmlFor="override-banner-cta-text">Override cta text</label>
                <S.ResetButton onClick={() => setValue("bannerCtaText", data.bannerCtaText.default)} />
              </S.Row>
              <S.TextInput
                value={data.bannerCtaText.value}
                onChange={e => setValue("bannerCtaText", e.target.value)}
                disabled={!data.bannerCtaText.override}
              />
            </S.Section>
          </S.TabPanel>
        )}
        <S.Separator />
        <p>
          Any changes you make are
          <span style={{ fontWeight: "bold" }}> temporary</span>
        </p>
      </S.EditorPanel>
      <S.FileInput
        type="file"
        ref={r => {
          fileInputRefs.current.mobile = r
        }}
        onChange={e => handleEditorModeImageChange(e, DEVICE.mobile)}
      />
      <S.FileInput
        type="file"
        ref={r => {
          fileInputRefs.current.desktop = r
        }}
        onChange={e => handleEditorModeImageChange(e, DEVICE.desktop)}
      />
    </>
  )

  return {
    Editor: component,
    bannerImage: getBannerImage(),
    bannerPreSpinTitle: data.bannerPreSpinTitle.override ? data.bannerPreSpinTitle.value : null,
    bannerOffer: data.bannerOffer.override ? data.bannerOffer.value : null,
    bannerSpinButtonText: data.bannerSpinButtonText.override ? data.bannerSpinButtonText.value : null,
    bannerCtaText: data.bannerCtaText.override ? data.bannerCtaText.value : null
  }
}

export default useEditorMode
