import React, { useLayoutEffect, useRef } from "react"
import PropTypes from "prop-types"
import { gsap, Power1 } from "gsap"
import rehypeRaw from "rehype-raw"

import { GtmEventType, GtmAction } from "../../utils/handlers/gtmHandler"
import { GATSBY_IMAGE_FIXED_PROPTYPE } from "../../helpers/constants"
import LUButton from "../LUButton"
import LUBannerLogos from "../LUBannerLogos"

import * as S from "./style"

const offerMarkdownClassName = "offerMarkdown"

const LUSpinningWheelBannerOverlay = ({ offer, legalLine, ctaText, ctaUrl, ctaShouldPulsate, ctaIconUrl, timeline, paymentLogos, isCanada }) => {
  const rootRef = useRef()
  const darkOverlayRef = useRef()
  const curtainRef = useRef()
  const confettiRef = useRef()
  const buttonContainerRef = useRef()
  const paymentBarRef = useRef()

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      if (!timeline) return

      const querySelector = gsap.utils.selector(rootRef)
      const offerMarkdownTarget = querySelector(`.${offerMarkdownClassName}:scope > p, h1, h2, h3`)
      const offerMarkdownGroupTarget = querySelector(`.${offerMarkdownClassName}`)

      timeline
        // Make overlay clickable again and fade in
        .to(rootRef.current, { duration: 1, pointerEvents: "unset", opacity: 1, ease: Power1.easeInOut }, "stage_2")
        // Bring curtain down
        .to(curtainRef.current, { duration: 1, translateY: 0, ease: Power1.easeInOut }, "stage_2")
        // Bring button down and fade in
        .to(buttonContainerRef.current, { duration: 1, opacity: 1, translateY: 0, ease: Power1.easeInOut }, "stage_2")
        // Bring markdown down
        .to(offerMarkdownGroupTarget, { duration: 1, translateY: 0, ease: Power1.easeInOut }, "stage_2")
        // Scale each markdown child up and down again.
        .to(offerMarkdownTarget, {
          keyframes: {
            scale: [1, 1.15, 1]
          },
          stagger: 0.075,
          duration: 0.5
        })
    })

    return () => ctx.revert()
  }, [timeline])

  const ctaGtmData = {
    eventType: GtmEventType.BUTTON_CLICK,
    action: GtmAction.MAIN_CTA_CLICK,
    label: ctaText
  }

  return (
    <S.DynamicBannerOverlay ref={rootRef}>
      <S.DarkOverlay ref={darkOverlayRef} />
      <S.Curtain ref={curtainRef} />
      <S.Confetti ref={confettiRef} />
      <S.Markdown rehypePlugins={[rehypeRaw]} className={offerMarkdownClassName}>
        {offer}
      </S.Markdown>

      <S.ButtonContainer ref={buttonContainerRef}>
        <LUButton url={ctaUrl} height="60px" bold gtmData={ctaGtmData} isPulsating={ctaShouldPulsate} iconUrl={ctaIconUrl}>
          {ctaText}
        </LUButton>
      </S.ButtonContainer>

      {legalLine && (
        <S.LegalLine rehypePlugins={[rehypeRaw]} arePaymentLogosVisibile={!!paymentLogos}>
          {legalLine}
        </S.LegalLine>
      )}
      {paymentLogos && <LUBannerLogos forwardedRef={paymentBarRef} images={paymentLogos} centered isCanada={isCanada} />}
    </S.DynamicBannerOverlay>
  )
}

LUSpinningWheelBannerOverlay.propTypes = {
  offer: PropTypes.string.isRequired,
  legalLine: PropTypes.string,
  ctaText: PropTypes.string.isRequired,
  ctaUrl: PropTypes.string.isRequired,
  ctaShouldPulsate: PropTypes.bool.isRequired,
  ctaIconUrl: PropTypes.string,
  timeline: PropTypes.func.isRequired,
  paymentLogos: PropTypes.arrayOf(
    PropTypes.shape({
      fixed: GATSBY_IMAGE_FIXED_PROPTYPE,
      title: PropTypes.string,
      description: PropTypes.string
    })
  ),
  isCanada: PropTypes.bool
}

LUSpinningWheelBannerOverlay.defaultProps = {
  legalLine: null,
  ctaIconUrl: null,
  paymentLogos: null,
  isCanada: false
}

export default LUSpinningWheelBannerOverlay
