import styled, { css } from "styled-components"
import ReactMarkdown from "react-markdown"

import { palette } from "../../styles"

import { Res } from "../../utils/constants"

import { Cta } from "../LUButton/style"

const MIN_HEIGHT_MOBILE = 500
const MIN_HEIGHT_DESKTOP = "540px"
const HEIGHT_DESKTOP = "75vh"
const MAX_HEIGHT_DESKTOP = "800px"

export const BannerMobile = styled.div`
  display: flex;
  position: relative;
  z-index: 1;

  width: 100%;

  max-height: 509px;

  @media (max-height: 600px) {
    max-height: 195px;
  }

  @media (min-width: 512px) {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    max-height: unset;

    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

export const Inner = styled.div`
  position: relative;

  z-index: 11;

  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: calc(100% - ${props => props.theme.boxLayout.margin.raw * 2}px);
  max-width: 1280px;

  margin: ${props => `70px ${props.theme.boxLayout.margin.value} 0`};
  box-sizing: border-box;

  background-color: #fff;

  border-radius: 12px;

  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);

  @media (min-width: 512px) {
    z-index: 1;
    margin: ${props => `${props.theme.components.navigationBar.atTopHeight.pixels} ${props.theme.boxLayout.margin.value} 0`};
    justify-content: center;
    background-color: #f0f0f0;
    min-height: ${MIN_HEIGHT_DESKTOP};
    height: ${HEIGHT_DESKTOP};
    max-height: ${MAX_HEIGHT_DESKTOP};
  }
`

export const Content = styled.div.attrs(props => ({
  padding: props.theme.isMobile ? 20 : 40
}))`
  z-index: 2;

  padding: 0px ${props => props.padding}px;

  margin-bottom: 25px;

  margin: 25px 0;
  height: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: 512px) {
    width: 43%;

    justify-content: center;

    ${Cta} {
      min-height: 58px;

      margin-top: 24px;
    }
  }
`

const textAttributes = css`
  position: relative;

  margin: 0;

  font-family: ${props => props.theme.fonts.leoUniverse};

  p {
    margin: 0;
  }
`

export const LegalLine = styled(ReactMarkdown)`
  ${textAttributes};

  font-family: ${props => props.theme.fonts.primary};
  font-weight: normal;

  display: flex;

  font-size: 0.9rem;
  line-height: 0.9rem;
  color: #000;
  padding: 10px 20px;

  text-align: center;
  background-color: #ededed;

  flex-direction: column;

  a {
    color: #000;
  }

  @media (min-width: 512px) {
    font-size: 1.1rem;
    line-height: 1.2rem;
    color: #fff;
    width: 90%;

    background: none;
    padding: 0;

    text-align: left;
    margin-top: 24px;

    a {
      color: #fff;
    }
  }
`

export const LoginText = styled.p`
  color: ${props => (props.theme.isBlackText ? "#000" : "#fff")};
  position: relative;
  font-size: 1.2rem;
  font-weight: 300;
  margin-top: 12px;
`

export const LoginLink = styled.a`
  color: ${props => (props.theme.isBlackText ? "#000" : "#fff")};
  letter-spacing: 0.2px;
  font-weight: 600;
  cursor: pointer;
  text-decoration: underline;
`

const h1 = css`
  ${textAttributes}

  font-size: 3.5rem;
  font-weight: 900;
  margin: 10px 0;
  line-height: normal;

  @media (min-width: ${Res._768}px) {
    font-size: 3.8rem;
  }

  @media (min-width: 1000px) {
    font-size: 4rem;
  }
`
const h2 = css`
  ${textAttributes}

  margin: 20px 0;

  font-size: 2.4rem;

  line-height: normal;

  font-weight: 700;

  ${props =>
    props.theme.isMobile &&
    css`
      margin: 10px 0;
      font-size: 2rem;
      font-weight: 600;
    `}
`
const h3 = css`
  ${textAttributes}

  margin-top: 20px;

  font-size: 1.6rem;
  line-height: normal;

  font-weight: 400;

  ${props =>
    props.theme.isMobile &&
    css`
      margin-top: 15px;
      font-size: 1.2rem;
      font-weight: 600;
    `}
`
export const Markdown = styled(ReactMarkdown)`
  ${props =>
    props.theme.isMobile
      ? css`
          height: 100%;
          justify-content: center;
          display: flex;
          flex-direction: column;
          color: #000;
          text-align: center;
        `
      : css`
          text-align: left;
          color: #fff;
          height: auto;
        `}

  ${props =>
    props.isItalic &&
    css`
      font-style: italic;
    `}

  h1 {
    ${h1}
  }

  h2 {
    ${h2}
  }

  h3 {
    ${h3}
  }

  ul,
  ol {
    ${textAttributes}
    margin: 5px 0;
    padding-left: 20px;
    li {
      font-size: 1.6rem;
      font-weight: normal;
      line-height: 21px;
    }
  }
`

export const NewMarkdown = styled(ReactMarkdown)`
  height: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  text-align: center;

  ${props =>
    props.$isOrangeBgLayout &&
    css`
      color: ${props.$isSports ? "#000" : "#fff"};
    `}

  h1,
  h2,
  h3,
  strong {
    font-family: ${props => props.theme.fonts.leoUniverse};

    margin: 0;
    font-weight: 600;
    line-height: normal;
    font-size: 100%;
  }

  strong {
    font-weight: 900;
  }

  h1,
  h2 {
    margin: 0 auto;
  }

  h3 {
    margin: 10px auto;
  }

  @media (min-width: 512px) {
    text-align: left;
    color: #fff;
    height: auto;
    h3 {
      margin: 10px 0;
    }
  }

  ${props =>
    props.$isSports
      ? css`
          div:nth-child(3) {
            margin-top: 10px;
          }

          h1,
          h2,
          h3 {
            display: block;
            position: relative;
            font-style: italic;
            line-height: normal;
            font-weight: 900;

            width: fit-content;

            :before,
            :after {
              content: "";
              display: block;
              z-index: -1;
              position: absolute;
              top: 0;
              bottom: 0;
              left: -7px;
              background-color: #fff;
              width: 15px;
              transform: skew(-10deg);
            }
            :after {
              right: -7px;
              left: auto;
            }
            del {
              position: relative;
              text-decoration: none;

              :before {
                position: absolute;
                content: "";
                left: -5px;
                top: 45%;
                right: -5px;
                border-top: 3px solid;
                border-color: inherit;
                -webkit-transform: skewY(-12deg);
                -moz-transform: skewY(-12deg);
                transform: skewY(-12deg);

                @media (min-width: 512px) {
                  border-top: 5px solid;
                }
              }
            }
          }
          h1 {
            background: #fff;
            font-size: unset;
            color: #f18825;
            text-transform: uppercase;
            @media (min-width: 512px) {
              margin-right: 0;
              margin-left: 20px;
            }
          }
          h2 {
            background: #fff;
            font-size: unset;
            color: #333231;
            text-transform: uppercase;

            ${!props.$isOrangeBgLayout &&
            css`
              margin-top: 5px;
            `}

            @media (min-width: 512px) {
              margin-top: 0;
              margin-right: 0;
              margin-left: 22px;
            }
          }
          h3 {
            background: #fff;
            font-size: unset;
            color: #333231;
            text-transform: uppercase;
            @media (min-width: 512px) {
              margin-top: 0;
            }
          }
          strong {
            font-size: 1.2em;
            color: #f18825;

            @media (min-width: 512px) {
              font-size: 1.4em;
            }
          }
        `
      : css`
          h1,
          h2,
          h3 {
            strong {
              // color: ${props.$isOrangeBgLayout ? "#fff" : "#ff6600"};
              font-size: 1.2em;

              @media (min-width: 512px) {
                font-size: 1.2em;
              }
            }
          }
          h2 {
            margin-top: 5px;
          }
        `}
`

export const PaymentLogos = styled.div`
  display: flex;
  position: absolute;
  right: 0px;
  left: 0px;
  bottom: 0px;
`

export const CtaContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  margin-top: 30px;

  a {
    width: 100%;
    font-size: 1.5rem;
  }

  @media (min-width: 512px) {
    display: block;
    margin: 0;
    a {
      width: unset;
    }
  }

  a {
    margin: 0;
  }

  @media (max-height: 738px) {
    flex-direction: row;
    align-items: center;

    a {
      flex: 1;
    }
  }
`

export const Arrows = styled.div`
  display: none;
  flex-direction: row;

  width: 27px;
  height: 20px;

  justify-content: end;

  :first-child {
    justify-content: end;
    transform: rotate(180deg);
  }

  @media (max-height: 738px) {
    display: flex;
  }
`

export const BottomArrow = styled.div`
  width: 45px;
  height: 10px;

  display: flex;
  flex-direction: column;

  margin: 20px auto 0;

  img {
    width: 100%;

    :not(:first-child) {
      margin-top: -7px;
    }
  }

  @media (max-height: 738px) {
    display: none;
  }
`

export const Ribbon = styled.div`
  position: absolute;
  top: 8px;
  right: 0;
  z-index: 11;

  height: 25px;
  padding: 2px 10px 2px 5px;
  margin-left: auto;
  margin-bottom: 40px;

  background-color: #fff;

  line-height: 25px;
  font-weight: bold;
  text-transform: uppercase;
  color: ${palette.crusta};

  :before {
    content: "";
    display: block;

    z-index: -1;

    position: absolute;
    top: 0;
    left: -10px;

    background-color: #fff;
    height: 29px;
    width: 25px;

    transform: skew(-10deg);
  }

  @media (min-width: 512px) {
    top: 50px;
    height: 30px;
    line-height: 30px;

    :before {
      height: 34px;
    }
  }

  ${props =>
    !props.$isSports &&
    css`
      :before {
        transform: skew(-15deg);
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
    `}

  span {
    position: relative;
    font-size: 1.2rem;
  }
`

export const RibbonText = styled.div`
  display: flex;
  align-items: center;

  p {
    margin: 0;
    display: inline-flex;
  }

  img {
    max-height: 28px;
    margin-right: 20px;
  }

  @media (max-width: ${Res._768}px) {
    p {
      font-size: 1.1rem;
    }
    img {
      max-height: 25px;
      margin-right: 10px;
    }
  }
`

export const BannerBadge = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 2;

  max-width: 160px;

  margin: 0 25px 90px 0;

  @media (max-width: 512px) {
    width: 25%;

    padding: 10px;
    margin: 0 10px 50px 0;
  }

  img {
    position: relative;
    width: 100%;
  }
`

export const Banner = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;

  background-color: #f7f7f7;
  position: relative;

  @media (min-width: 768px) {
    @supports (top: clamp(${MIN_HEIGHT_DESKTOP}, ${HEIGHT_DESKTOP}, ${MAX_HEIGHT_DESKTOP})) {
      :after {
        content: "";

        position: absolute;
        top: calc(75vh + ${props => props.theme.components.navigationBar.atTopHeight.pixels});
        left: 0;
        bottom: 0;
        /* transform: translateY(100%); */

        z-index: -1;

        height: 200px;
        width: 100%;

        background-color: #f7f7f7;

        clip-path: ellipse(60vw 25% at 50vw 0%);

        @media (min-width: 768px) {
          clip-path: ellipse(60vw 80% at 50vw 0%);
        }

        @media (max-width: 899px) and (orientation: landscape) {
          top: calc(${MIN_HEIGHT_MOBILE}px + ${props => props.theme.components.navigationBar.atTopHeight.pixels});
        }
        @media (min-width: 900px) {
          top: calc(
            clamp(${MIN_HEIGHT_DESKTOP}, ${HEIGHT_DESKTOP}, ${MAX_HEIGHT_DESKTOP}) +
              ${props => props.theme.components.navigationBar.atTopHeight.pixels}
          );
        }
      }
    }
  }

  ${props =>
    props.$isOrangeBgLayout &&
    `
    @media (max-width: 511px) {
      :after {
        content: none;
      }
      ${Inner} {
        width: 100%;
        margin: 55px 0 0;
        background-color: #fff;
        border-radius: 0;
      }
      ${Content} {
        padding: 25px 20px 65px;
        height: 100%;
        max-width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background: #ff6600;
        margin-top: -40px;
        margin-bottom: 0;

        @media (max-height: 600px) {
          margin-top: -30px;
        }
      }
      ${Markdown} {
          color: #fff;
      }
      ${PaymentLogos} {
        position: relative;
        z-index: 111;
      }
      ${CtaContainer} {
        margin: 25px 0;
      }
    }
    `}
`
