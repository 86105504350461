import ReactMarkdown from "react-markdown"
import styled, { css } from "styled-components"

import ConfettiDesktopImage from "../../assets/images/dynamic-overlay/confetti-desktop.png"
import ConfettiMobileImage from "../../assets/images/dynamic-overlay/confetti-mobile.png"

import { Res } from "../../utils/constants"

export const DynamicBannerOverlay = styled.div`
  z-index: 1;
  position: absolute;
  inset: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px 0;

  opacity: 0;

  pointer-events: none;
`

export const DarkOverlay = styled.div`
  position: absolute;
  inset: 0;

  background-color: rgba(0, 0, 0, 0.75);
`

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;

  width: 60%;

  opacity: 0;

  transform: translateY(-200%);

  @media (min-width: 900px) {
    width: 30%;
  }

  a,
  button {
    flex-grow: 1;
  }
`

export const Curtain = styled.div`
  position: absolute;
  inset: 0 0 20% 0;

  background: linear-gradient(180deg, rgba(255, 102, 0, 0.8) 0%, rgba(241, 130, 37, 0.8) 100%);
  clip-path: polygon(0% 100%, 50% 80%, 100% 100%, 100% 0%, 0% 0%);

  @media (min-width: 900px) {
    clip-path: polygon(0% 100%, 50% 70%, 100% 100%, 100% 0%, 0% 0%);
  }

  transform: translateY(-100%);

  ::after {
    content: "";

    position: absolute;
    inset: 0;

    background: radial-gradient(rgba(253, 240, 140, 0.8) 0%, rgba(253, 240, 140, 0) 100%);

    /* one row is 6 values. */
    clip-path: polygon(
      0% 100%,
      50% 80%,
      100% 100%,
      100% 99.5%,
      50% 79.5%,
      0% 99.5%,
      0% 97%,
      50% 77%,
      100% 97%,
      100% 96.5%,
      50% 76.5%,
      0% 96.5%,
      0% 94%,
      50% 74%,
      100% 94%,
      100% 93.5%,
      50% 73.5%,
      0% 93.5%,
      0% 91%,
      50% 71%,
      100% 91%,
      100% 90.5%,
      50% 70.5%,
      0% 90.5%,
      0% 88%,
      50% 68%,
      100% 88%,
      100% 87.5%,
      50% 67.5%,
      0% 87.5%
    );

    @media (min-width: 900px) {
      clip-path: polygon(
        0% 100%,
        50% 70%,
        100% 100%,
        100% 99.5%,
        50% 69.5%,
        0% 99.5%,
        0% 97%,
        50% 67%,
        100% 97%,
        100% 96.5%,
        50% 66.5%,
        0% 96.5%,
        0% 94%,
        50% 64%,
        100% 94%,
        100% 93.5%,
        50% 63.5%,
        0% 93.5%,
        0% 91%,
        50% 61%,
        100% 91%,
        100% 90.5%,
        50% 60.5%,
        0% 90.5%,
        0% 88%,
        50% 58%,
        100% 88%,
        100% 87.5%,
        50% 57.5%,
        0% 87.5%
      );
    }
  }
`

export const Confetti = styled.div`
  position: absolute;
  inset: 0;

  background-image: url(${ConfettiMobileImage});
  background-size: 100% auto;

  @media (min-width: 512px) {
    background-image: url(${ConfettiDesktopImage});
    background-repeat: no-repeat;
    background-position: 50%;
    background-size: auto;
  }
`

export const Arrows = styled.div`
  ::after {
    content: "";

    position: absolute;
    inset: 0;

    background: radial-gradient(rgba(253, 240, 140, 0.8) 0%, rgba(253, 240, 140, 0) 100%);

    /* one row is 6 values. */
    clip-path: polygon(
      0% 100%,
      50% 80%,
      100% 100%,
      100% 99.5%,
      50% 79.5%,
      0% 99.5%,
      0% 97%,
      50% 77%,
      100% 97%,
      100% 96.5%,
      50% 76.5%,
      0% 96.5%,
      0% 94%,
      50% 74%,
      100% 94%,
      100% 93.5%,
      50% 73.5%,
      0% 93.5%,
      0% 91%,
      50% 71%,
      100% 91%,
      100% 90.5%,
      50% 70.5%,
      0% 90.5%,
      0% 88%,
      50% 68%,
      100% 88%,
      100% 87.5%,
      50% 67.5%,
      0% 87.5%
    );

    @media (min-width: 900px) {
      clip-path: polygon(
        0% 100%,
        50% 70%,
        100% 100%,
        100% 99.5%,
        50% 69.5%,
        0% 99.5%,
        0% 97%,
        50% 67%,
        100% 97%,
        100% 96.5%,
        50% 66.5%,
        0% 96.5%,
        0% 94%,
        50% 64%,
        100% 94%,
        100% 93.5%,
        50% 63.5%,
        0% 93.5%,
        0% 91%,
        50% 61%,
        100% 91%,
        100% 90.5%,
        50% 60.5%,
        0% 90.5%,
        0% 88%,
        50% 58%,
        100% 88%,
        100% 87.5%,
        50% 57.5%,
        0% 87.5%
      );
    }
  }
`

const textAttributes = css`
  display: flex;
  flex-direction: column;
  justify-content: center;

  font-family: ${props => props.theme.fonts.leoUniverse};

  color: ${props => (props.theme.isBlackText ? "#000" : "#fff")};

  p {
    margin: 0;
  }
`

export const Markdown = styled(ReactMarkdown)`
  position: relative;
  z-index: 1;
  margin-top: 10%;
  margin-left: 10%;
  margin-right: 10%;

  ${textAttributes};

  font-weight: bold;
  text-align: center;

  font-size: 2.5rem;
  line-height: 3.5rem;

  width: fit-content;

  transform: translateY(-100%);

  div {
    position: relative;

    margin: 0 auto;
    padding: 0 3%;

    ::before {
      content: "";

      position: absolute;
      inset: -30%;

      background: radial-gradient(closest-side, rgba(255, 102, 0, 0.5) 50%, rgba(0, 0, 0, 0) 100%);
    }
  }

  h1,
  h2,
  h3 {
    position: relative;
    z-index: 1;

    margin: 0;
  }

  h1 {
    font-size: 3rem;
    line-height: 4rem;
  }

  h2 {
    font-size: 2.5rem;
    line-height: 3.5rem;
  }

  h3 {
    font-size: 2rem;
    line-height: 3rem;
  }

  @media (min-width: ${Res._512}px) {
    font-size: 6rem;
    line-height: 7rem;

    h1 {
      font-size: 5rem;
      line-height: 6rem;
    }

    h2 {
      font-size: 3rem;
      line-height: 4rem;
    }

    h3 {
      font-size: 2rem;
      line-height: 3rem;
    }
  }

  @media (min-width: 900px) {
    width: 100%;

    font-size: 5rem;
    line-height: 6rem;

    margin-top: 5%;

    h1 {
      font-size: 5rem;
      line-height: 6rem;
    }

    h2 {
      font-size: 4rem;
      line-height: 5rem;
    }

    h3 {
      font-size: 2.5rem;
      line-height: 3.5rem;
    }
  }
`

export const LegalLine = styled(ReactMarkdown)`
  z-index: 1;
  position: relative;

  ${textAttributes};

  display: flex;

  width: fit-content;
  max-width: 65%;

  ${({ arePaymentLogosVisibile }) =>
    arePaymentLogosVisibile
      ? css`
          margin: auto auto calc(2.5% + 54px);

          @media (min-width: 900px) {
            margin: auto auto 10%;
          }
        `
      : css`
          margin: auto auto 2.5%;
        `}

  font-family: ${props => props.theme.fonts.primary};
  font-size: 0.8rem;
  font-weight: normal;
  line-height: 9px;
  text-align: center;

  a {
    color: ${props => (props.theme.isBlackText ? "#000" : "#fff")};
  }

  @media (min-width: ${Res._512}px) {
    font-size: 1.2rem;
    line-height: 1.4rem;
  }
`
