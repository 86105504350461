import React, { useLayoutEffect, useRef, useState } from "react"
import Img from "gatsby-image"
import PropTypes from "prop-types"
import { gsap, Power1 } from "gsap"
import rehypeRaw from "rehype-raw"

import { GATSBY_IMAGE_FLUID_PROPTYPE, GATSBY_IMAGE_FIXED_PROPTYPE } from "../../helpers/constants"

import * as S from "./style"
import { getMarkdownHtml } from "../../helpers/index"
import useEditorMode from "../LUEditorMode"
import LUSpinningWheel from "../LUSpinningWheel"
import LUSpinningWheelBannerOverlay from "../LUSpinningWheelBannerOverlay"
import LUButton from "../LUButton"

const imageStyleProps = {
  style: {
    position: "absolute",
    top: 0,
    width: "100%",
    height: "100%"
  },
  imgStyle: {
    objectFit: "cover"
  }
}

const preSpinTitleMarkdownClassName = "preSpinTitleMarkdown"

const LUSpinningWheelBanner = ({
  fluidImage,
  preSpinTitle,
  offer,
  legalLine,
  ctaText,
  ctaUrl,
  ctaShouldPulsate,
  ctaIconUrl,
  spinButtonText,
  uniqueId,
  paymentLogos,
  isCanada
}) => {
  const localStorageId = `wheel-id-${uniqueId}`
  const [timeline, setTimeline] = useState(null)
  const [isComplete, setIsComplete] = useState(typeof window !== "undefined" && !!localStorage.getItem(localStorageId))

  const contentRef = useRef()
  const colouredOverlayRef = useRef()
  const buttonRef = useRef()

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      const tl = gsap.timeline({
        paused: true,
        onComplete: () => {
          setIsComplete(true)
          localStorage.setItem(localStorageId, true)
        }
      })

      const mm = gsap.matchMedia()

      // Mobile
      mm.add("(max-width: 900px)", () => {
        // Fade out the spin CTA
        tl.to(buttonRef.current, { duration: 1, opacity: 0 }, "stage_1").to(
          `.${preSpinTitleMarkdownClassName}`,
          { duration: 0.75, opacity: 0, ease: Power1.easeInOut },
          "stage_1"
        )
      })

      // Desktop
      mm.add("(min-width: 900px)", () => {
        tl
          // Slide the coloured overlay to the left
          .to(colouredOverlayRef.current, { duration: 0.75, translateX: "-100%", opacity: 0, ease: Power1.easeInOut }, "stage_1")
          // Slide the markdown to the left.
          .to(`.${preSpinTitleMarkdownClassName}`, { duration: 0.75, translateX: "-100%", opacity: 0, ease: Power1.easeInOut }, "stage_1")
      })

      setTimeline(tl)
    })

    return () => ctx.revert()
  }, [])

  useLayoutEffect(() => {
    if (!timeline) return

    const ctx = gsap.context(() => {
      if (isComplete) {
        timeline.progress(1).pause()
      }
    })

    // eslint-disable-next-line consistent-return
    return () => ctx.revert()
  }, [timeline])

  const startTimeline = () => {
    timeline.play()
  }

  const editorMode = useEditorMode({
    bannerImage: fluidImage,
    bannerSpinWheelSpun: isComplete,
    bannerSpinWheelResetCallback: () => {
      setIsComplete(false)
      localStorage.removeItem(localStorageId)
      timeline.progress(0).pause()
    },
    bannerPreSpinTitle: preSpinTitle,
    bannerOffer: offer,
    bannerSpinButtonText: spinButtonText,
    bannerCtaText: ctaText
  })

  return (
    <S.Banner>
      <S.Inner>
        <S.ColouredOverlay ref={colouredOverlayRef} />

        {editorMode?.Editor}

        {fluidImage && <Img fluid={editorMode?.bannerImage ?? fluidImage} {...imageStyleProps} />}

        <S.Content ref={contentRef}>
          <S.PreSpinTitle className={preSpinTitleMarkdownClassName} rehypePlugins={[rehypeRaw]}>
            {editorMode?.bannerPreSpinTitle ?? getMarkdownHtml(preSpinTitle)}
          </S.PreSpinTitle>
          <S.ButtonContainer ref={buttonRef}>
            <LUButton handleClick={startTimeline} bold isPulsating={ctaShouldPulsate} iconUrl={ctaIconUrl}>
              {editorMode?.bannerSpinButtonText ?? spinButtonText}
            </LUButton>
          </S.ButtonContainer>
          <LUSpinningWheel spinButtonText={editorMode?.bannerSpinButtonText ?? spinButtonText} timeline={timeline} onClick={startTimeline} />
        </S.Content>

        <LUSpinningWheelBannerOverlay
          offer={editorMode?.bannerOffer ?? getMarkdownHtml(offer)}
          legalLine={legalLine}
          ctaText={editorMode?.bannerCtaText ?? ctaText}
          ctaUrl={ctaUrl}
          ctaIconUrl={ctaIconUrl}
          ctaShouldPulsate={ctaShouldPulsate}
          timeline={timeline}
          paymentLogos={paymentLogos}
          isCanada={isCanada}
        />
      </S.Inner>
    </S.Banner>
  )
}

LUSpinningWheelBanner.propTypes = {
  fluidImage: GATSBY_IMAGE_FLUID_PROPTYPE.isRequired,
  preSpinTitle: PropTypes.string,
  offer: PropTypes.string.isRequired,
  legalLine: PropTypes.string,
  ctaText: PropTypes.string.isRequired,
  ctaUrl: PropTypes.string.isRequired,
  ctaShouldPulsate: PropTypes.bool,
  ctaIconUrl: PropTypes.string,
  spinButtonText: PropTypes.string.isRequired,
  uniqueId: PropTypes.string,
  paymentLogos: PropTypes.arrayOf(
    PropTypes.shape({
      fixed: GATSBY_IMAGE_FIXED_PROPTYPE,
      title: PropTypes.string,
      description: PropTypes.string
    })
  ),
  isCanada: PropTypes.bool
}

LUSpinningWheelBanner.defaultProps = {
  preSpinTitle: null,
  legalLine: null,
  ctaShouldPulsate: false,
  ctaIconUrl: null,
  uniqueId: null,
  paymentLogos: null,
  isCanada: false
}

export default LUSpinningWheelBanner
