import styled, { css } from "styled-components"
import { Res } from "../../utils/constants"
import { hideScrollbars } from "../../styles/common"

const LAYOUT_CHANGE_BREAKPOINT = 860

export const PaymentProviderBar = styled.div`
  position: absolute;
  right: 0;
  left: 0;

  ${({ mobilePosition }) =>
    mobilePosition === "top"
      ? css`
          top: 0;
        `
      : css`
          bottom: 0;
        `}

  z-index: 1;

  display: flex;
  justify-content: center;

  background-color: rgba(0, 0, 0, 0.8);

  @media (min-width: ${LAYOUT_CHANGE_BREAKPOINT}px) {
    background-color: rgba(0, 0, 0, 0.6);
    height: 74px;
    justify-content: flex-start;
  }

  @media (min-width: ${Res._512}px) {
    top: unset;
    bottom: 0;
  }
`

export const Images = styled.div`
  ${hideScrollbars};

  position: relative;
  overflow-x: scroll;

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;

  height: 38px;
  padding: 1px 15px;

  ${({ centered }) => centered && "margin: 0 auto"};

  border-radius: 12px;

  picture {
    box-sizing: border-box;
    height: 100%;
    margin: 0 10px;
    display: flex;
    align-items: center;

    img {
      width: auto;
      height: auto;
      max-height: 23px;
    }

    :last-child {
      padding-right: 30px;
    }
  }

  @media (min-width: ${Res._768}px) {
    overflow-x: auto;
    padding: 7px 15px;

    picture {
      margin: 0 10px;

      img {
        width: auto;
        height: auto;
        max-height: 27px;
      }
    }
  }

  @media (min-width: ${Res._1024}px) {
    padding: 7px 50px;
  }
`

export const InteracLogo = styled.img`
  margin: 0 10px;
  height: 30px;
`
