import styled from "styled-components"
import { Res } from "../../utils/constants"

export const LUSocialProof = styled.div`
  background: linear-gradient(to right, #da803a, #ff5630);
  margin: 60px 0 0;
`

export const Inner = styled.div`
  max-width: 1280px;
  padding: 48px 16px;
  margin: 0 auto;

  @media (min-width: ${Res._768}px) {
    max-width: 850px;
  }
`

export const Numbers = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 40px;

  @media (min-width: ${Res._768}px) {
    grid-column-gap: 60px;
    grid-row-gap: 0px;
    grid-template-columns: repeat(2, 1fr);
  }
`

export const LURunningNumber = styled.div`
  padding: 40px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: White;
  border-radius: 10px;
  min-width: 200px;
  box-shadow: 0px 0px 10px 1px rgb(0 0 0 / 6%);
`

export const Title = styled.h4`
  font-size: 4em;
  margin: 0;
`

export const Caption = styled.p`
  font-size: 1.15em;
  margin-bottom: 0;
`
