import React from "react"
import PropTypes from "prop-types"
import rehypeRaw from "rehype-raw"

import * as S from "./style"

import { pushToDataLayer, GtmEventType, GtmAction, GtmEventCategory } from "../../utils/handlers/gtmHandler"

const handleStepClick = step => pushToDataLayer(GtmEventType.BUTTON_CLICK, GtmAction.THREE_STEP_CARD_CLICK, step, GtmEventCategory.CLICK)

const LUThreeSteps = ({ stepContent, overlap, redirectUrl, arePaymentsDisplayedInBanner, backgroundColour }) => {
  const stepText = step => {
    return `<h4>${step[0]}</h4><p>${step[1]}</p>`
  }

  const stepData = [
    {
      text: stepText(stepContent.stepOne),
      gtmLabel: "First Step"
    },
    {
      text: stepText(stepContent.stepTwo),
      gtmLabel: "Second Step"
    },
    {
      text: stepText(stepContent.stepThree),
      gtmLabel: "Third Step"
    }
  ]

  return (
    <S.ThreeSteps overlap={overlap} arePaymentsDisplayedInBanner={arePaymentsDisplayedInBanner}>
      <S.Inner>
        {stepData.map(({ text, gtmLabel }, ind) => (
          <S.Step key={text} href={redirectUrl} onClick={() => handleStepClick(gtmLabel)} index={ind} arrowIndentColour={backgroundColour}>
            <S.IconContainer>
              <S.NumberIcon>
                <span>{ind + 1}</span>
              </S.NumberIcon>
            </S.IconContainer>
            <S.Description rehypePlugins={[rehypeRaw]}>{text}</S.Description>
          </S.Step>
        ))}
      </S.Inner>
    </S.ThreeSteps>
  )
}

LUThreeSteps.propTypes = {
  stepContent: PropTypes.objectOf(PropTypes.any).isRequired,
  redirectUrl: PropTypes.string.isRequired,
  overlap: PropTypes.bool,
  arePaymentsDisplayedInBanner: PropTypes.bool,
  backgroundColour: PropTypes.string
}

LUThreeSteps.defaultProps = {
  overlap: true,
  arePaymentsDisplayedInBanner: false,
  backgroundColour: "#ededed"
}

export default LUThreeSteps
