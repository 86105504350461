import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { Res } from "../../utils/constants"
// import { GtmEventType, GtmAction, GtmEventCategory, pushToDataLayer } from "../../utils/handlers/gtmHandler"

import * as S from "./style"

/*
  const gtmData = {
    eventType: GtmEventType.BUTTON_CLICK,
    action: GtmAction.BANNERFLOW_DYNAMIC_BANNER_CLICK,
    label: "Bannerflow Dynamic Banner"
  }
*/

const BannerflowBanner = ({ bannerflowDesktopBanner, bannerflowMobileBanner }) => {
  const [scriptSrc, setScriptSrc] = useState()

  const isMobile = typeof window !== "undefined" && window.innerWidth <= Res._768

  // const refScript = useRef()

  /* const handleClick = () => {
    if (!gtmData) return

    const { eventType, action, label } = gtmData

    pushToDataLayer(eventType, action, label, GtmEventCategory.CLICK)
  } */

  useEffect(() => {
    const src = isMobile ? bannerflowMobileBanner : bannerflowDesktopBanner

    const finalSrc = `${src}&redirecturl=off`

    setScriptSrc(finalSrc)

    // refScript.current.addEventListener("click", handleClick)

    // return () => refScript.current.removeEventListener("click", handleClick)
  }, [])

  return (
    <S.BannerContainer>
      <S.Inner>
        <script src={scriptSrc} async />
      </S.Inner>
    </S.BannerContainer>
  )
}

BannerflowBanner.propTypes = {
  bannerflowDesktopBanner: PropTypes.string.isRequired,
  bannerflowMobileBanner: PropTypes.string.isRequired
}

export default BannerflowBanner
