function pushDatalayer(action, label, redirectUrl) {
  dataLayer.push({
    event: "buttonClick",
    eventCategory: "Click",
    eventAction: action,
    eventLabel: label
  })

  if (redirectUrl) {
    window.location.href = redirectUrl
  }
}

function pushDataDimensions(dimensionValue, dimensionNumber) {
  dataLayer.push({
    event: "ourCampaignDecided",
    optimizelyOurDimensionValue: dimensionValue,
    optimizelyOurDimensionNumber: dimensionNumber
  })
}

export { pushDatalayer, pushDataDimensions }
