import styled, { css, keyframes } from "styled-components"

import settingsIcon from "../../assets/images/icons/icon-settings.svg"
import undoIcon from "../../assets/images/icons/icon-undo.svg"

const SPACING = "8px"
const BORDER_RADIUS = "4px"

export const EditorToggleButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;

  z-index: 999;

  width: 32px;
  height: 32px;

  border: none;
  border-radius: ${BORDER_RADIUS};

  background-color: #fff;
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);

  :hover {
    cursor: pointer;
  }

  ::before {
    content: "";

    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 100%;

    border-radius: ${BORDER_RADIUS};

    background-image: url(${settingsIcon});
    background-position: 50%;
    background-size: 60%;
    background-repeat: no-repeat;

    transition: transform 0.1s ease-in-out;

    transform: ${({ active }) => (active ? "rotateZ(-90deg)" : "rotateZ(0)")};
  }
`
export const EditorPanel = styled.div`
  position: absolute;
  top: 60px;
  right: 20px;

  z-index: 999;

  display: flex;
  flex-direction: column;
  gap: ${SPACING};

  min-width: 260px;

  padding: 16px;

  border-radius: ${BORDER_RADIUS};

  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);

  background-color: #fff;

  transition: opacity 0.2s ease-in-out, transform 0.2s ease-in-out;

  ${({ active }) =>
    active
      ? css`
          opacity: 1;
          transform: translateY(0);
        `
      : css`
          opacity: 0;
          pointer-events: none;
          transform: translateY(-5px);
        `}

  p {
    width: 100%;

    margin: 0;
    padding: 4px;

    box-sizing: border-box;
    border-radius: ${BORDER_RADIUS};

    text-align: center;
    font-size: 0.8em;
    line-height: 1.3em;
    font-style: italic;

    background-color: #e2e2e2;
  }
`

export const Heading = styled.span`
  width: 100%;

  font-weight: bold;
`

export const SubHeading = styled.span`
  width: 100%;

  font-size: 0.75em;
  color: #aaa;
`

export const EditorPanelButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  height: 32px;
  padding: ${SPACING};

  border: 1px solid #000;
  border-radius: ${BORDER_RADIUS};

  background-color: #fff;

  color: #000;
  font-size: 1.5em;

  transition: background-color 0.1s ease-in-out;

  :hover {
    background-color: #000;

    color: #fff;

    cursor: pointer;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
  }

  :active {
    background-color: #e2e2e2;
  }
`

export const Button = styled.button`
  height: 32px;

  border: 1px solid #000;
  border-radius: ${BORDER_RADIUS};

  background-color: #000;

  color: #fff;

  transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out;

  :hover {
    cursor: pointer;

    background-color: transparent;

    color: #000;
  }

  :disabled {
    cursor: not-allowed;

    border: 1px solid #bbb;
    background-color: #bbb;
    color: #777;
  }
`

export const FileUploadArea = styled.button`
  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 6px;

  border: 1px solid ${({ active }) => (active ? "#f37f19" : "#eee")};
  border-radius: ${BORDER_RADIUS};

  background-color: ${({ active }) => (active ? "rgb(243, 127, 25, 0.2)" : "#fff")};

  transition: background-color 0.1s ease-in-out, border 0.1s ease-in-out;

  :hover {
    cursor: pointer;

    background-color: #eee;
  }

  span {
    margin-top: ${SPACING};

    font-size: 0.8em;
  }

  img {
    max-height: 100px;
    object-fit: contain;
  }
`

export const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 28px;
  height: 28px;

  background-color: #f8f8f8;
  padding: ${SPACING};
  border-radius: 50%;
`

export const FileInput = styled.input`
  display: none;
`

export const Separator = styled.div`
  height: 1px;
  width: 100%;
  background-color: #eee;
`

export const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;

  width: 100%;

  input {
    margin: 0;
  }

  button {
    margin-left: auto;
  }
`

export const TabContainer = styled.div`
  display: flex;

  height: 32px;
  width: 100%;

  margin-bottom: ${SPACING};
`

export const TabButton = styled.button`
  flex-grow: 1;

  transition: background-color 0.1s ease-in-out, color 0.1s ease-in-out;

  ${({ active }) =>
    active
      ? css`
          background-color: #000;
          color: #fff;
        `
      : css`
          background-color: #ddd;
          color: #000;

          :hover {
            background-color: #bbb;
          }
        `}

  border: none;

  cursor: pointer;

  :nth-child(1) {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  :last-child {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
`

const fadeInAnim = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

export const TabPanel = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING};

  max-height: 300px;

  margin-right: -14px;
  padding-right: 14px;

  animation: ${fadeInAnim} 0.25s cubic-bezier(0.39, 0.575, 0.565, 1) both;

  overflow-y: scroll;
`

export const TextArea = styled.textarea`
  min-width: 260px;
  max-width: 600px;

  min-height: 150px;
  max-height: 400px;

  padding: ${SPACING};

  border-radius: ${BORDER_RADIUS};
  border: 1px solid #ddd;

  box-sizing: border-box;

  :disabled {
    background-color: #eee;
  }
`

export const TextInput = styled.input.attrs({ type: "text" })`
  padding: ${SPACING};

  border-radius: ${BORDER_RADIUS};
  border: 1px solid #ddd;

  box-sizing: border-box;

  :disabled {
    background-color: #eee;
  }
`

export const ResetButton = styled.button`
  width: 26px;
  height: 26px;

  border: 1px solid transparent;
  border-radius: ${BORDER_RADIUS};

  background-color: transparent;
  background-image: url(${undoIcon});
  background-position: 50%;
  background-size: 65%;
  background-repeat: no-repeat;

  transition: border 0.1s ease-in-out;

  :hover {
    border: 1px solid #000;
    cursor: pointer;
  }
`

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${SPACING};

  padding: ${SPACING} 0;
`
