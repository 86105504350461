import React, { useRef } from "react"
import PropTypes from "prop-types"

import * as S from "./style"

const LUSocialProof = ({ captions }) => {
  const element = useRef()

  return (
    <S.LUSocialProof>
      <S.Inner>
        <S.Numbers ref={element}>
          {captions.map(([value, description], key) => (
            <S.LURunningNumber key={key}>
              <S.Title>{`${Number(value).toLocaleString()}+`}</S.Title>
              <S.Caption>{description}</S.Caption>
            </S.LURunningNumber>
          ))}
        </S.Numbers>
      </S.Inner>
    </S.LUSocialProof>
  )
}

LUSocialProof.propTypes = {
  captions: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string))
}

LUSocialProof.defaultProps = {
  captions: []
}

export default LUSocialProof
