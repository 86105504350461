import React, { useRef, useEffect, useLayoutEffect } from "react"
import PropTypes from "prop-types"
import { gsap, Back, Power1, Linear } from "gsap"

import * as S from "./style"

// The length of the animation.
const SPIN_DURATION = 5

// The wheel blur animation keyframes.
const wheelBlurKeyframes = {
  "25%": { filter: "blur(1px)" },
  "35%": { filter: "blur(3px)" },
  "85%": { filter: "blur(1px)" },
  "90%": { filter: "blur(0px)" }
}

const LUSpinningWheel = ({ spinButtonText, timeline, onClick }) => {
  const rootRef = useRef()
  const borderRef = useRef()
  const wheelRef = useRef()
  const spinButtonRef = useRef()
  const pointerRef = useRef()

  useEffect(() => {
    gsap.to(borderRef.current, { duration: 20, rotateZ: -360, ease: Linear.easeNone }).repeat(-1)
  }, [])

  useLayoutEffect(() => {
    const ctx = gsap.context(() => {
      if (!timeline) return

      const mm = gsap.matchMedia()

      // Fade out the spin button.
      timeline.to(spinButtonRef.current, { duration: 0.75, opacity: 0, pointerEvents: "none", ease: Power1.easeInOut }, "stage_1")

      // Mobile
      mm.add("(max-width: 900px)", () => {
        // Move the wheel to the center and scaled it down slightly.
        timeline.to(rootRef.current, { duration: 0.75, width: "85vw", bottom: "50%", translateY: "50%", translateX: "50%" }, "stage_1")
      })

      // Desktop
      mm.add("(min-width: 901px)", () => {
        // Move the wheel to the center.
        timeline.to(rootRef.current, { duration: 0.75, right: "50%", translateX: "50%", ease: Power1.easeInOut }, "stage_1")
      })

      timeline
        // Spin the wheel
        .to(wheelRef.current, { duration: SPIN_DURATION, rotateZ: 360 * 5, ease: Back.easeInOut.config(0.8) }, "stage_1")
        // Blur the wheel while it spins
        .to(wheelRef.current, { duration: SPIN_DURATION, keyframes: wheelBlurKeyframes }, "stage_1")
        // Scale the wheel up and down once it stops
        .to([wheelRef.current, borderRef.current], {
          duration: 0.5,
          keyframes: { scale: [1, 1.02, 1] }
        })

      // Desktop
      mm.add("(min-width: 901px)", () => {
        // Move the wheel to the center and scale it down slightly.
        timeline.to(rootRef.current, { duration: 1, translateY: "65%", ease: Power1.easeInOut }, "stage_2")
      })

      // Mobile
      mm.add("(max-width: 900px)", () => {
        // Move the wheel to the center.
        timeline.to(rootRef.current, { duration: 1, bottom: 0, translateY: "50%", ease: Power1.easeInOut }, "stage_2")
      })
    })
    return () => ctx.revert()
  }, [timeline])

  return (
    <S.SpinningWheel ref={rootRef}>
      <S.Wheel ref={wheelRef} />
      <S.Border ref={borderRef} />
      <S.Pointer ref={pointerRef} />
      <S.SpinButton ref={spinButtonRef} onClick={onClick}>
        {spinButtonText}
      </S.SpinButton>
    </S.SpinningWheel>
  )
}

LUSpinningWheel.propTypes = {
  spinButtonText: PropTypes.string.isRequired,
  timeline: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired
}

export default LUSpinningWheel
