import styled from "styled-components"

const LAYOUT_CHANGE_BREAKPOINT = 860

export const BannerContainer = styled.div`
  position: relative;
  z-index: 1;

  padding: 24px 16px 36px;

  @media (min-width: ${LAYOUT_CHANGE_BREAKPOINT}px) {
    padding: 29px 16px;
  }
`

export const Inner = styled.div`
  position: relative;

  z-index: 1;

  display: flex;
  flex-direction: column;

  max-width: 1180px;

  margin: 0 auto;

  box-sizing: border-box;

  border-radius: 12px;
  overflow: hidden;

  box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.05);

  transition: box-shadow 0.25s ease-in-out;

  :hover {
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.2);
  }

  @media (min-width: ${LAYOUT_CHANGE_BREAKPOINT}px) {
    width: 100%;

    flex-direction: row;
    justify-content: space-around;
  }
`
