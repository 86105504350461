import React from "react"
import PropTypes from "prop-types"

import LUFixedImage from "../LUFixedImage"
import { GATSBY_IMAGE_FIXED_PROPTYPE } from "../../helpers/constants"
import interac from "../../assets/interac.png"

import * as S from "./style"

const LUBannerLogos = ({ images, centered, forwardedRef, mobilePosition = "bottom", isCanada }) => (
  <S.PaymentProviderBar mobilePosition={mobilePosition} {...(forwardedRef && { ref: forwardedRef })}>
    <S.Images centered={centered}>
      {isCanada && <S.InteracLogo src={interac} alt="interac" width="30px" height="30px" />}
      {images.map(({ fixed, title, description }, index) => (
        <LUFixedImage key={index} fixed={fixed} title={title} alt={description} />
      ))}
    </S.Images>
  </S.PaymentProviderBar>
)

LUBannerLogos.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      fixed: GATSBY_IMAGE_FIXED_PROPTYPE,
      title: PropTypes.string,
      description: PropTypes.string
    })
  ).isRequired,
  centered: PropTypes.bool,
  forwardedRef: PropTypes.func,
  mobilePosition: PropTypes.oneOf(["top", "bottom"]),
  isCanada: PropTypes.bool
}

LUBannerLogos.defaultProps = {
  centered: false,
  forwardedRef: null,
  mobilePosition: "bottom",
  isCanada: false
}

export default LUBannerLogos
